import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Badges from './badges'
// import SignUpForm from "./sign-up-form"
import * as heroInnerStyles from "./hero-inner.module.css"

const HeroInner = () => {

  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "hero-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
    <div className={heroInnerStyles.hero_inner}>
      <div className={heroInnerStyles.hero_inner_text}>
        <h1 className={heroInnerStyles.hero_title}>How well do you know me?</h1>
        <h2 className={heroInnerStyles.hero_body_text}>To Know Me is a trivia game about yourself. Answer questions and then guess each other's answers! Play with a friend, multiple friends, or strangers!</h2>
        <Badges />
      </div>
      <div className={heroInnerStyles.hero_image_wrapper}>
        <Img className={heroInnerStyles.hero_image} fluid={data.heroImage.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }}/>
      </div>
    </div>
    </>
  )
}

export default HeroInner
