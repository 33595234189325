import React from "react"
import * as howToPlayStyles from "./how-to-play.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import wheelGIF from '../images/gifs/wheel.gif'

const HowToPlay = ({ children }) => {

  const data = useStaticQuery(graphql`
    query {
      answerQuestion: file(relativePath: { eq: "answer-question.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      matchAnswers: file(relativePath: { eq: "match-answers.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      selectAnswer: file(relativePath: { eq: "select-answer.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div className={howToPlayStyles.how_to_play} id="how-to-play">
        <div className={howToPlayStyles.how_to_play_container}>
          <h2 className={howToPlayStyles.title}>Learn How To Play</h2>
          <div className={howToPlayStyles.section_container}>
            <div className={howToPlayStyles.section_image_container}>
              <img src={wheelGIF} alt="Spinning wheel" />
            </div>
            <div className={howToPlayStyles.section_text_container}>
              <h3 className={howToPlayStyles.section_title}>Spin the wheel</h3>
              <p className={howToPlayStyles.section_body_text}>The wheel is split into six sections representing categories including: Challenges, Experiences, Goals, Values, Emotions, and Random.</p>
            </div>
          </div>
          <div className={howToPlayStyles.section_container}>
            <div className={howToPlayStyles.section_image_container}>
              <Img fluid={data.answerQuestion.childImageSharp.fluid} />
            </div>
            <div className={howToPlayStyles.section_text_container}>
              <h3 className={howToPlayStyles.section_title}>Answer the question</h3>
              <p className={howToPlayStyles.section_body_text}>When the wheel stops, players will answer the given question from the chosen category. The question will either be multiple choice or free response.</p>
            </div>
          </div>
          <div className={howToPlayStyles.section_container}>
            <div className={howToPlayStyles.section_image_container}>
              <Img fluid={data.selectAnswer.childImageSharp.fluid} />
            </div>
            <div className={howToPlayStyles.section_text_container}>
              <h3 className={howToPlayStyles.section_title}>Play 1-on-1 and guess</h3>
              <p className={howToPlayStyles.section_body_text}>After answering the question, the player will have to guess the other player’s answer. One point is scored for a correct answer.</p>
            </div>
          </div>
          <div className={howToPlayStyles.section_container}>
            <div className={howToPlayStyles.section_image_container}>
              <Img fluid={data.matchAnswers.childImageSharp.fluid} />
            </div>
            <div className={howToPlayStyles.section_text_container}>
              <h3 className={howToPlayStyles.section_title}>Play with a group and match</h3>
              <p className={howToPlayStyles.section_body_text}>In multi-player mode, players will guess each player’s answers by dragging and dropping a profile to a corresponding response. One point is scored for every correct answer.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HowToPlay
