import React from "react"
import * as badgesStyles from "./badges.module.css"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Badges = () => {

  const data = useStaticQuery(graphql`
    query {
      googlePlayBadge: file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
          fluid(maxHeight: 48) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      appleBadge: file(relativePath: { eq: "apple-app-store.png" }) {
        childImageSharp {
          fluid(maxHeight: 48) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <div className={badgesStyles.badgesRow}>
        <div className={badgesStyles.badge}>
          <a href="https://apps.apple.com/us/app/to-know-me/id1558779180" alt='get it on Apple App Store'>
            <Img style={{ height: "100%" }} fluid={data.appleBadge.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }}/>
          </a>
        </div>
        <div className={badgesStyles.badge}>
            <a href='https://play.google.com/store/apps/details?id=app.toknowme.toknowme&utm_source=website&utm_campaign=android_app_download&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <Img style={{ height: "100%" }} fluid={data.googlePlayBadge.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }}/>
            </a>
        </div>
      </div>
    </>
  )
}

export default Badges
