import React from "react"
import PropTypes from "prop-types"

import * as heroStyles from "./hero.module.css"
import "./hero.css"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Hero = ({ children }) => {

  return (
    <div className={heroStyles.hero}>
      {children}
    </div> 
  )
}

Hero.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Hero
