import React from "react"

import Hero from "../components/hero"
import Layout from "../components/layout"
import HeroInner from "../components/hero-inner"
import HowToPlay from "../components/how-to-play"
import "./index-css-modules.module.css"

const IndexPage = () => {
  return (
    <>
    <Layout>
      <Hero>
        <HeroInner/>
      </Hero>
      <HowToPlay></HowToPlay>
    </Layout>
      
    </>
  )
}

export default IndexPage
